import Vue from 'vue';
import VueRouter, { RawLocation, Route, RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Quiz from '@/views/Quiz.vue';
import Start from '@/views/Start.vue';
import Submitted from '@/views/Submitted.vue';
import Tip from '@/views/Tip.vue';
import Calibration from '@/views/Calibration.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Start.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz,
  },
  {
    path: '/ing',
    name: 'Quiz',
    component: Quiz,
  },
  {
    path: '/submitted',
    name: 'Submitted',
    component: Submitted,
  },
  {
    path: '/tip',
    name: 'Tip',
    component: Tip,
  },
  {
    path: '/calibration',
    name: 'Calibration',
    component: Calibration,
  },
];

const router = new VueRouter({
  routes,
});

/**
 * Ignore NavigationDuplicated error
 *
 * @see https://stackoverflow.com/a/67102038/5731332
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      // on complete

      resolve(this.currentRoute);
    }, (error) => {
      // on abort

      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export default router;
