import liff from '@line/liff/dist/lib';
import LoggingApi from '@/apis/LoggingApi';
import QuizApi from '@/apis/QuizApi';

export default class QuizUtils {
  static async sendAnswerReport(quizCode: string): Promise<string> {
    if (liff.isInClient()) {
      LoggingApi.log('sent liff result message');
      liff.sendMessages([{
        type: 'text',
        text: `complete.${quizCode}`,
      }]);

      LoggingApi.log(`sent message: complete.${quizCode}`);
    }
    LoggingApi.log('sent browser result message');
    const result = QuizApi.browserSubmit(quizCode, '{}');
    return result;
  }
}
