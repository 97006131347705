



















import Vue, { PropType } from 'vue';
import StageBlock from '@/components/stage/StageBlock.vue';
import { QuizModel } from '@/apis/models/QuizModel';

export default Vue.extend({
  name: 'Stage',
  components: {
    StageBlock,
  },
  props: {
    imageDisplayWidth: {
      type: Number,
      required: false,
      default: 300,
    },
    quiz: {
      type: Object as PropType<QuizModel>,
      required: false,
      default: null as QuizModel | null,
    },
    multipleSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    userAnswer: {
      type: Array as () => Array<string>,
      required: false,
      default: [''],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    imageRatio(): number {
      if (this.quiz) {
        return this.imageDisplayWidth / this.quiz.baseImage.width;
      }
      return 0;
    },
    stageSize(): object {
      const maxWidth = this.imageDisplayWidth;
      let maxHeight = maxWidth;
      if (this.quiz && this.quiz.baseImage) {
        maxHeight = this.quiz.baseImage.height * this.imageRatio;
      }

      return {
        'max-width': `${maxWidth}px`,
        'max-height': `${maxHeight}px`,
        'min-width': `${maxWidth}px`,
      };
    },
  },
  created() {
    this.$store.commit('updateStageHighlightLabel', this.userAnswer);
    // console.log('userAns： ');
    // console.log(this.userAnswer[0]);
  },
  updated() {
    this.$store.commit('updateStageHighlightLabel', this.userAnswer);
    // console.log('update function - userAns： ');
    // console.log(this.userAnswer[0]);
  },
  methods: {
    updateParentAnsSet(e: string) {
      this.$emit('updateAnsSetFromSub', this.$store.state.stageHighlightLabel);
    },
    itemClicked() {
      (this as any).$parent.checkIsAllSelected();
    },
  },
});
