import Vue from 'vue';

/**
 * List all events:
 * * reset-stage
 * * before-screenshot
 * * after-screenshot
 */
export const StageEventBus = new Vue();
/**
 * List all events:
 * * reset
 */
export const SelectionEventBus = new Vue();

/**
 * List all events:
 * * stop-all-audios
 */
export const AudioPlayEventBus = new Vue();

export default {
  StageEventBus,
  SelectionEventBus,
  AudioPlayEventBus,
};
