// eslint-disable-next-line import/prefer-default-export
import { CreateRespondModel } from '@/apis/models/CreateModel';

const example = {
  answerList: [{
    userAnswer: [],
    timeSpent: 39,
    multipleSelect: false,
    startTime: 1659116526256,
    endTime: 0,
    uuid: 'f52f9e6d-96d5-4f84-a6f0-3e65c0e2a0ed',
    sourceQuiz: {
      score: 1,
      templateName: 'title four image',
      clickAreas: [{
        area: {
          x: 0, width: 1040, y: 0, height: 520,
        },
        action: { type: '' },
        label: 'Title',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '1 + 1 = ?\n', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 0, width: 520, y: 520, height: 520,
        },
        action: { type: 'answer' },
        label: 'A',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '7', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 520, width: 520, y: 520, height: 520,
        },
        action: { type: 'answer' },
        label: 'B',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '9\n', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 0, width: 520, y: 1040, height: 520,
        },
        action: { type: 'answer' },
        label: 'C',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '2', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 520, width: 520, y: 1040, height: 520,
        },
        action: { type: 'answer' },
        label: 'D',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '10', enabled: true, paddingY: 80,
          },
        },
      }],
      multipleSelect: false,
      baseImage: { width: 1040, url: 'https://storage.googleapis.com/quiz-storage/quiz-templates/standard_grid.jpg', height: 1560 },
      templateId: '1234a7b6-78f0-465b-a6bb-fa02df2c24b8',
      enableAudio: false,
      audioDuration: 0,
      uuid: '4621460a-4dad-493a-84f4-fe153f877aee',
    },
  }, {
    userAnswer: [],
    timeSpent: 0,
    multipleSelect: false,
    startTime: 0,
    endTime: 0,
    uuid: 'e84878e0-0c47-4c83-9658-a3346c149b58',
    sourceQuiz: {
      score: 1,
      templateName: 'title four image',
      clickAreas: [{
        area: {
          x: 0, width: 1040, y: 0, height: 520,
        },
        action: { type: '' },
        label: 'Title',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '10 - 1 = ?\n', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 0, width: 520, y: 520, height: 520,
        },
        action: { type: 'answer' },
        label: 'A',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '1', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 520, width: 520, y: 520, height: 520,
        },
        action: { type: 'answer' },
        label: 'B',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '9', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 0, width: 520, y: 1040, height: 520,
        },
        action: { type: 'answer' },
        label: 'C',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '6', enabled: true, paddingY: 80,
          },
        },
      }, {
        area: {
          x: 520, width: 520, y: 1040, height: 520,
        },
        action: { type: 'answer' },
        label: 'D',
        content: {
          imageField: {
            paddingX: 80, size: 80, radius: 16, enabled: false, url: '', paddingY: 80,
          },
          textField: {
            paddingX: 80, size: 24, text: '3', enabled: true, paddingY: 80,
          },
        },
      }],
      multipleSelect: false,
      baseImage: { width: 1040, url: 'https://storage.googleapis.com/quiz-storage/quiz-templates/standard_grid.jpg', height: 1560 },
      templateId: '1234a7b6-78f0-465b-a6bb-fa02df2c24b8',
      enableAudio: false,
      audioDuration: 0,
      uuid: 'fdd84aa5-98eb-447b-895a-769d1f30be06',
    },
  }],
  sourceQuizGroupName: 'Example',
  shortId: 'vthh0',
  amount: 2,
  sourceQuizGroupId: '6bf72ef4-f0e7-4d14-aa36-0cad3deb161a',
  ownerId: '3b0ae5d4-58d5-4a9b-beff-dbea09163e78',
  uuid: '23530f96-54e2-46c7-befe-61f72a448f21',
  status: 'TEMP_SUBMITTED',
} as CreateRespondModel;

export default {
  example,
};
