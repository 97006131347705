











import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      clickCount: 5,
    };
  },
  methods: {
    doubleClicked() {
      console.log('double clicked');
    },
    cycleClicked() {
      this.clickCount -= 1;
      if (this.clickCount === 0) {
        this.clickCount = 5;
        this.$emit('stepPlus');
      }
    },
  },
});

