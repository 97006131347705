import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_LOGGING_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default class LoggingApi {
  static async log(message: string): Promise<void> {
    await api.get(`/log/${message}`);
  }
}
