





























































import Vue, { PropType } from 'vue';
import StageText from '@/components/stage/StageText.vue';
import StageImage from '@/components/stage/StageImage.vue';
import { StageEventBus } from '@/libs/EventBus';
import { ContentModel } from '@/apis/models/QuizModel';
// import { ContentModel } from '@/apis/models/QuizModel';
// import { StageOptionModel } from '@/models/StageOptionModel';

export default Vue.extend({
  name: 'StageBlock',
  components: {
    StageText,
    StageImage,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scaleRatio: {
      type: Number,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: Object as PropType<ContentModel>,
      required: false,
      default: () => ({}),
    },
    multipleSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      displayAnswer: true, // used to control whether the Stage/StageBlock should display the answer
    };
  },
  computed: {
    wrapperStyle(): object {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    quizAnswer(): string {
      return this.$store.state.stageAnswer;
    },

    isAnswer(): boolean {
      return String(this.quizAnswer) === this.label;
    },
    isHighlight(): boolean {
      if (this.actionType === 'answer') {
        if (this.multipleSelect === false) {
          const { stageHighlightLabel } = this.$store.state;
          if (stageHighlightLabel[0] === undefined || stageHighlightLabel[0] === null || stageHighlightLabel === null) return false;
          return this.$store.state.stageHighlightLabel[0] === this.label;
        }
        if (this.multipleSelect) {
          const { stageHighlightLabel } = this.$store.state;
          for (let i = 0; i < stageHighlightLabel.length; i += 1) {
            if (stageHighlightLabel[i] === this.label) {
              return true;
            }
          }
          return false;
        }
      }
      return false;
    },
  },
  mounted() {
    StageEventBus.$on('reset-stage', this.reset);
  },
  methods: {
    reset() {
      this.displayAnswer = false;
    },
    toggleAnswer() {
      // TODO: you should design a structure to record the answer
      this.$store.commit('updateStageHighlightLabel', this.label);
    },
    highlightMe() {
      if (this.actionType === 'answer') {
        if (this.multipleSelect === false) {
          const { stageHighlightLabel } = this.$store.state;
          if (stageHighlightLabel[0] === this.label) {
            stageHighlightLabel.pop();
          } else {
            this.$store.commit('updateStageHighlightLabel', [this.label]);
          }
        } else if (this.multipleSelect) {
          const { stageHighlightLabel } = this.$store.state;
          let flag = false;
          for (let i = 0; i < stageHighlightLabel.length; i += 1) {
            if (stageHighlightLabel[i] === this.label) {
              stageHighlightLabel.splice(i, 1);
              flag = true;
            }
          }
          if (!flag) {
            stageHighlightLabel.push(this.label);
          }
          this.$store.commit('updateStageHighlightLabel', stageHighlightLabel);
        }
        this.$emit('updateAnsSet', '');
      }
      (this as any).$parent.itemClicked();
    },
  },
});
