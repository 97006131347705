









import Vue from 'vue';

export default Vue.extend({
  computed: {
    displayOverlay() {
      return this.$store.state.displayOverlay;
    },
  },
});
