export default class Utils {
  static deepcopy(obj: unknown) {
    return JSON.parse(JSON.stringify(obj));
  }

  static randomString(length: number): string {
    if (length < 0) return '';
    return Math.random().toString(36).substring(length);
  }

  static preloadImage(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const preloadImg = new Image();
      preloadImg.onload = () => {
        resolve();
      };
      preloadImg.onerror = () => {
        reject(new Error(`Failed to load image from ${url}`));
      };
      preloadImg.src = url;
    });
  }

  /**
   * demo purpose function, no use
   */
  private static demo(chance = 0.5) {
    return new Promise((resolve, reject) => {
      const rand = Math.random();
      if (rand > chance) resolve(rand);
      else {
        reject(new Error(`less than ${chance}`));
      }
    });
  }

  /**
   * Timeout function + Promise
   */
  static wait(delay: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });
  }

  /**
   * Retry a task
   *
   * usage:
   * retry(Utils.demo, [0.7]).then((rand) => {
   *  console.log('ok, we got ${rand} pass')
   * }).catch((error) => {
   *  console.log(`oh no, no one pass, and here is the reason: ${error}`)
   * })
   *
   * @param task task to execute and retry, task should be a promise function
   * @param params parameters for the task
   * @param retries if task failed, retry execute task how many times
   * @param delay the delay between each retry
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async retry(task: Function, params: any[], retries = 5, delay = 100): Promise<any> {
    retries = Math.max(retries, 0);
    delay = Math.max(delay, 10);

    let failedReason = null;

    for (let i = 0; i < retries; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        return await task(...params);
      } catch (error) {
        failedReason = error;
        // console.log(`failed ${i + 1} times`);
      }

      // eslint-disable-next-line no-await-in-loop
      await Utils.wait(delay);
    }

    throw failedReason;
  }

  static fileToDataUrl(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e && e.target && typeof (e.target.result) === 'string') {
          const dataUrl = e.target.result;
          resolve(dataUrl);
        } else {
          reject(new Error('Failed to read file as dataUrl'));
        }
      };
      reader.readAsDataURL(file);
    });
  }
}
