









import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  mounted() {
    if (this.$route.query.clientType === 'rn') {
      window.ReactNativeWebView.postMessage('submitted');
    }
  },
  created() {
    const clientType = this.$route.query.clientType as string;
    const u = navigator.userAgent;
    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
      if (clientType !== 'rn') {
        window.AndroidWebView.showResultAndVideo();
      }
    }

    if (clientType === 'rn') { // For React Native APP
      window.ReactNativeWebView.postMessage('submitted');
    }
  },
  // methods: {
  //   postMessageToRN() {
  //     window.ReactNativeWebView.postMessage('submitted');
  //   },
  // },
});
